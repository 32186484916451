import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';

const StyledHeader = styled.div`
  width: 100%;
  height: 44px;
  background-color: #384D9C;
`;

const StyledName = styled.div`
  width: 96%;
  color: white;
  font-size: 10pt;
  height: 24px;
  line-height: 24px;
  overflow: hidden;
  font-family: Montserrat-SemiBold;
`;

const StyledSku = styled.div`
  width: 96%;
  color: white;
  font-size: 9pt;
`;

function HeaderLarge({ product, updateProduct }) {
    const initialName = product.name;
    const [updatedName, setUpdatedName] = useState(initialName);
    const changeName = () => {
        const newName = prompt("Sửa tên sản phẩm", initialName);
        if(newName){
          setUpdatedName(newName);
          updateProduct({...product, name: newName});
        }
    };
  return (
    <StyledHeader>
      <Tooltip onClick = {() => {changeName()}} placement="right" title="Click để sửa tên">
        <StyledName className="mx-auto">{updatedName}</StyledName>
      </Tooltip>
      <StyledSku className="mx-auto">SKU: {product.sku}</StyledSku>
    </StyledHeader>
  );
};

export default HeaderLarge;
