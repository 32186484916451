import React from "react";
import styled from "styled-components";
import { getInstallmentAmountOneMonth } from "../../helpers/installment";

const StyledFooter = styled.div`
  width: 100%;
  border-top: 2px solid #384D9C;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 37px;
  justify-content: space-evenly;
`;
const StylePriceBox = styled.div`
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 13px;
`;
const StyledPrice = styled.div`
  font-size: 9pt;
  text-decoration: line-through;
`;

const StyledFinalPrice = styled.div`
  color: #384D9C;
  font-size: 13pt;
  font-family: Montserrat-Bold;
`;

const StyledInstallment = styled.div`
  text-align: center;
  font-size: 10px;
  margin-top: -10px;
`;

const StyledInstallmentPrice = styled.strong`
  color: #384D9C;
`;

function FooterSmall({ product, installmentInfo }) {
  const amount = getInstallmentAmountOneMonth(
    product.finalPrice,
    installmentInfo.month,
    installmentInfo.interestRate
  );
  return (
    <StyledFooter>
      <StylePriceBox>
        <StyledFinalPrice>
          {product.finalPrice ? product.finalPrice.toLocaleString() : "0"}đ
        </StyledFinalPrice>

        {product.finalPrice < product.price && (
          <StyledPrice>
            {product.price ? product.price.toLocaleString() : "0"}đ
          </StyledPrice>
        )}
      </StylePriceBox>
      {installmentInfo.month > 0 && (
        <StyledInstallment>
          {`Trả góp ${installmentInfo.interestRate || 0}%,`} &nbsp;
          <StyledInstallmentPrice>
            {`${amount.toLocaleString()} đ/ tháng`}
          </StyledInstallmentPrice>
        </StyledInstallment>
      )}
    </StyledFooter>
  );
}

export default FooterSmall;
